import { createUseStyles } from 'react-jss';
import React from 'react';
import Divider from './Divider';

const useStyles = createUseStyles((theme) => ({
  root: {
    fontSize: theme.fontSizeNormal,
    marginBottom: '1em',
    textAlign: 'center',
  },
  credit: {
    fontSize: theme.fontSizeSmall,
  },
}));

const Footer = (props) => {
  const classes = useStyles(props);
  return (
    <footer className={classes.root}>
      <Divider />
      <a href="https://download.teamviewer.com/qs">
        Remote Support
      </a>
      <br />
      <br />
      <span className={classes.credit}>
        ©
        {new Date().getFullYear()}
        , Site by
        {' '}
        {/* eslint-disable-next-line react/jsx-no-target-blank */}
        <a href="https://brycejmeyer.com" rel="noopener" target="_blank">Bryce J. Meyer</a>
      </span>
    </footer>
  );
};

export default Footer;
