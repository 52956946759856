import { StaticQuery, graphql } from 'gatsby';
import { createUseStyles } from 'react-jss';
import PropTypes from 'prop-types';
import React from 'react';
import Footer from './Footer';
import Header from './Header';

const useStyles = createUseStyles((theme) => ({
  root: {
    fontSize: theme.fontSizeNormal,
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const Layout = ({ children, ...props }) => {
  const classes = useStyles(props);
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={(data) => (
        <>
          <Header siteTitle={data.site.siteMetadata.title} />
          <div>
            <main className={classes.main}>
              {children}
            </main>
            <Footer />
          </div>
        </>
      )}
    />
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
