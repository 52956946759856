import { navigate } from 'gatsby';
import { createUseStyles } from 'react-jss';
import PropTypes from 'prop-types';
import React from 'react';
import HeaderMenuItem from './HeaderMenuItem';
import ImageLogo from './images/ImageLogo';

const useStyles = createUseStyles(theme => ({
  root: {
    alignItems: 'stretch',
    backgroundColor: '#333',
    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    borderBottom: `3px solid ${theme.colorPrimary}`,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    position: 'relative',
    zIndex: 1100,
  },
  logoContainer: {
    cursor: 'pointer',
    flex: '1 1 0',
    minWidth: '250px',
    padding: '16px 48px',
  },
  logoLink: {
    color: 'white',
    fontWeight: 'bold',
    textDecoration: 'none',
  },
  menuContainer: {
    alignItems: 'stretch',
    display: 'flex',
    flexWrap: 'wrap',
    flex: '3 1 auto',
    justifyContent: 'center',
  },
}));

const handleLoginClick = () => {
  window.location.href = `https://app.${window.location.hostname}/`;
};

const Header = (props) => {
  const {
    siteTitle,
  } = props;
  const classes = useStyles(props);
  return (
    <header className={classes.root}>
      <div
        className={classes.logoContainer}
        onClick={() => navigate('/')}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            navigate('/');
          }
        }}
        role="button"
        tabIndex="-1"
      >
        <ImageLogo title={siteTitle} />
      </div>
      <div className={classes.menuContainer}>
        <HeaderMenuItem link="/features" text="FEATURES" />
        <HeaderMenuItem link="/pricing" text="PRICING" />
        <HeaderMenuItem link="/contact" text="CONTACT" />
        <HeaderMenuItem func={handleLoginClick} text="LOG IN" />
      </div>
    </header>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: '',
};

export default Header;
