import { createUseStyles } from 'react-jss';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = createUseStyles(theme => ({
  root: {
    alignItems: 'stretch',
    cursor: 'pointer',
    display: 'flex',
    margin: '1em',
  },
  link: {
    alignItems: 'center',
    color: 'white',
    display: 'flex',
    textDecoration: 'none',
    transition: 'color .15s ease-in-out, font-weight .15s ease-in-out',
    '&:hover': {
      color: theme.colorSecondary,
    },
  },
  linkActive: {
    borderBottom: '2px solid white',
  },
}));

const HeaderMenuItem = (props) => {
  const {
    func,
    link,
    text,
  } = props;
  const classes = useStyles(props);
  return (
    <div className={classes.root}>
      {func ? (
        <a className={classes.link} href={link} onClick={func}>
          <span>
            {text}
          </span>
        </a>
      ) : (
        <Link className={classes.link} activeClassName={classes.linkActive} to={link}>
          <span>
            {text}
          </span>
        </Link>
      )}
    </div>
  );
};

HeaderMenuItem.propTypes = {
  func: PropTypes.func,
  link: PropTypes.string,
  text: PropTypes.string.isRequired,
};

HeaderMenuItem.defaultProps = {
  func: () => {},
  link: '#',
};

export default HeaderMenuItem;
