import { createUseStyles } from 'react-jss';
import React from 'react';

const useStyles = createUseStyles(() => ({
  root: {
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
    border: '0',
    height: '1px',
    margin: '1em 0',
  },
}));

const Divider = (props) => {
  const classes = useStyles(props);
  return (
    <hr className={classes.root} {...props} />
  );
};

export default Divider;
